"use client";

import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "@/contexts/site/site.context";

export function useCookiebotConsent(): Consent {
  const site = useContext(SiteContext);
  const [consent, setConsent] = useState({
    marketing: false,
    necessary: false,
    preferences: false,
    statistics: false,
    submitted: false,
  });

  const honorConsentFromCookiebot = useCallback(() => {
    const myCookieBot = getConsentObject();
    if (myCookieBot !== null) {
      setConsent(myCookieBot);
    }
  }, [setConsent]);

  useEffect(() => {
    addCookiebotConsentListener(honorConsentFromCookiebot);
  }, [site]);

  return consent;
}

function getConsentObject(): Consent | null {
  /* global Cookiebot -- Cookiebot is injected by an external script. */
  if (typeof Cookiebot === "undefined") {
    // Cookiebot object is not available.
    return null;
  }
  if (!Cookiebot.consented && !Cookiebot.declined) {
    // User has neither consented nor declined, aka no consent submitted.
    return null;
  }

  return { ...Cookiebot.consent, submitted: true };
}

function addCookiebotConsentListener(honorConsentFromCookiebot: () => void) {
  window.addEventListener(
    "CookiebotOnConsentReady",
    honorConsentFromCookiebot,
    false,
  );
  if (typeof Cookiebot !== "undefined") {
    honorConsentFromCookiebot();
  }
}

/**
 * This type structure is shaped to match the response provided by Cookiebot.consent.
 */
type Consent = {
  readonly necessary: boolean;
  readonly preferences: boolean;
  readonly statistics: boolean;
  readonly marketing: boolean;
  readonly submitted: boolean;
  readonly stamp?: string;
  readonly method?: string;
};
