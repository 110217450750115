"use client";

import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";
const HeaderContext = createContext<Header | undefined>(undefined);
export function useHeader(): Header {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    // If you reach this statement, you have discovered a programming error :)
    throw new Error("An `NavigationProvider` must exist in the component hierarchy.");
  }
  return context;
}
type Header = {
  readonly isVisible: boolean;
  readonly setIsVisible: Dispatch<SetStateAction<boolean>>;
};
type HeaderProviderProps = {
  readonly children?: ReactNode;
};
export function HeaderProvider({
  children
}: HeaderProviderProps) {
  const [isVisible, setIsVisible] = useState(false);
  const result = useMemo(() => ({
    isVisible,
    setIsVisible
  }), [isVisible, setIsVisible]);
  return <HeaderContext.Provider value={result} data-sentry-element="unknown" data-sentry-component="HeaderProvider" data-sentry-source-file="header.context.tsx">{children}</HeaderContext.Provider>;
}