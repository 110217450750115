"use client";

import "client-only";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { SiteConfiguration } from "@/site-configuration/site-configuration.types";
export const SiteConfigurationContext = createContext<SiteConfiguration | undefined>(undefined);
export function useSiteConfiguration(): SiteConfiguration {
  const siteConfiguration = useContext(SiteConfigurationContext);
  if (siteConfiguration === undefined) {
    throw new Error("A siteConfiguration must be present in the component hierarchy.");
  }
  return siteConfiguration;
}
type SiteConfigurationProviderProps = {
  readonly siteConfiguration: SiteConfiguration;
  readonly children: ReactNode;
};
export function SiteConfigurationProvider({
  siteConfiguration,
  children
}: SiteConfigurationProviderProps) {
  return <SiteConfigurationContext.Provider value={siteConfiguration} data-sentry-element="unknown" data-sentry-component="SiteConfigurationProvider" data-sentry-source-file="site-configuration.context.tsx">
      {children}
    </SiteConfigurationContext.Provider>;
}