"use client";

import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { ServerConfiguration } from "@/services/configuration/server-configuration.types";
const ServerConfigurationContext = createContext<ServerConfiguration | undefined>(undefined);
export function useServerConfiguration(): ServerConfiguration {
  const serverConfiguration = useContext(ServerConfigurationContext);
  if (serverConfiguration === undefined) {
    throw new Error("A `ServerConfigurationProvider` must be present in the component hierarchy.");
  }
  return serverConfiguration;
}
type ServerConfigurationProviderProps = {
  readonly serverConfiguration: ServerConfiguration;
  readonly children: ReactNode;
};
export function ServerConfigurationProvider({
  serverConfiguration,
  children
}: ServerConfigurationProviderProps) {
  return <ServerConfigurationContext.Provider value={serverConfiguration} data-sentry-element="unknown" data-sentry-component="ServerConfigurationProvider" data-sentry-source-file="server-configuration.context.tsx">
      {children}
    </ServerConfigurationContext.Provider>;
}