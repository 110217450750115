import type { EventHint } from "@sentry/nextjs";
import { captureException } from "@sentry/nextjs";
import type { CaptureContext, ScopeContext } from "@sentry/types";

export type ExclusiveEventHintOrCaptureContext =
  | (CaptureContext &
      Partial<{
        readonly [key in keyof EventHint]: never;
      }>)
  | (EventHint &
      Partial<{
        readonly [key in keyof ScopeContext]: never;
      }>);

// A small wrapper around Sentry's captureException function
export const captureClientException = (
  exception: unknown,
  hint?: ExclusiveEventHintOrCaptureContext,
): string => captureException(exception, hint);
