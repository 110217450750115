"use client";

import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useCallback, useEffect, useRef } from "react";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
const facebookPixelId = 1_423_279_677_716_456;
export function FacebookPixel() {
  const consent = useCookiebotConsent();
  const isTrackedRef = useRef(false);
  const fbEvent = useCallback((type: string, value: string) => {
    window.fbq(type, value);
  }, []);
  const handleRouteChange = useCallback(() => {
    fbEvent("track", "PageView");
  }, [fbEvent]);
  useEffect(() => {
    if (!consent.submitted || isTrackedRef.current) {
      return;
    }
    if (consent.marketing) {
      fbEvent("consent", "grant");
    } else {
      fbEvent("consent", "revoke");
      return;
    }
    isTrackedRef.current = true;
    fbEvent("track", "PageView");
  }, [consent, fbEvent]);

  // separate useEffect to avoid unnecessary event-registrations
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => handleRouteChange, [handleRouteChange, pathname, searchParams]);
  return <Script id="facebook-pixel" strategy="afterInteractive" data-sentry-element="Script" data-sentry-component="FacebookPixel" data-sentry-source-file="FacebookPixel.component.tsx">
      {`
 !function(f,b,e,v,n,t,s)
 {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
 n.callMethod.apply(n,arguments):n.queue.push(arguments)};
 if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
 n.queue=[];t=b.createElement(e);t.async=!0;
 t.src=v;s=b.getElementsByTagName(e)[0];
 s.parentNode.insertBefore(t,s)}(window, document,'script',
 'https://connect.facebook.net/en_US/fbevents.js');
 fbq('consent', 'revoke');
 fbq('init', '${facebookPixelId}');
 `}
    </Script>;
}